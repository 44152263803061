import React from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'
import SwitchInput from '../../shared/inputs/SwitchInput'

class ConfigClaimBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...props.banner_info, error: false }
  }

  handleNameChange(fieldName, e) {
    let newValue = e.target.value
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: newValue
    }))
    $(`.tpa_name`)[0].setCustomValidity("");
    $(`.tpa_link`)[0].setCustomValidity("");
    $(`.claim_portal_text`)[0].setCustomValidity("");
  }

  saveBannerInfo() {
    let errorField;
    if (this.state.tpa_name == '' || this.state.tpa_link == '' || this.state.claim_portal_text == '') {
      this.setState(prevState => ({
        ...prevState,
        error: true
      }));
      if (this.state.tpa_name == '') {
        errorField = 'tpa_name'
      } else if (this.state.tpa_link == '') {
        errorField = 'tpa_link'
      } else {
        errorField = 'claim_portal_text'
      }
      $(`.${errorField}`)[0].setCustomValidity("Please fill out this field.");
      $(`.${errorField}`)[0].reportValidity();
      return;
    }
    let companyUrl = this.props.company_level ? 'companies/' :''
    let paramName = this.props.company_level ? 'company_claim_configuration' : 'claim_configuration'
    let url = `/admin/${companyUrl}claim_configurations/${this.state.id}/update_banner_info`
    $.ajax({
      method: 'PUT',
      url: url,
      data: {[paramName] : this.state},
      success: (response) => {
        if (response.success){
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      },
      failure: (error) => {
        toastr.error(error.message)
      }
    })
  }

  renderEnableBanner() {
    return (
      <div className="form-group" style={{
        paddingTop: 12
      }}>
        <div className="row">
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >Enable Claims Education Banner</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="enable_banner"
                checked={this.state.enabled_education_banner}
                id="enabled_education_banner"
                onChange={(checked) => this.handleChange('enabled_education_banner',checked)}
              />
            </div>
          </div>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
            </div>
            <div className="col-md-1">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.saveBannerInfo()}
              >Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }


  renderTextFields () {
    return (
      <>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>TPA Display Name</label>
                <input
                  type="text"
                  className="form-control tpa_name"
                  name="tpa_name"
                  value={ this.state.tpa_name }
                  onChange={ (e) => this.handleNameChange('tpa_name',e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>TPA Claims Portal Link</label>
                <input
                  type="text"
                  className="form-control tpa_link"
                  name="tpa_link"
                  value={ this.state.tpa_link }
                  onChange={ (e) => this.handleNameChange('tpa_link', e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
              <label>TPA Claims Portal Text to Display</label>
              <input
                type="text"
                className="form-control claim_portal_text"
                name="claim_portal_text"
                value={ this.state.claim_portal_text }
                onChange={ (e) => this.handleNameChange('claim_portal_text', e) }
                style={{
                  width: 500
                }}
              />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  handleChange(fieldName, checked) {
    this.setState(prevState => ({
          ...prevState,
          [fieldName]: checked
        })
    )
  }

  bannerToggles() {
    return (
      <>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >RLSD > 30 days in Future</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="rlsd_gt_30_future"
                checked={this.state.rlsd_gt_30_future}
                id="rlsd_gt_30_future"
                onChange={(checked) => this.handleChange('rlsd_gt_30_future',checked)}
              />
            </div>
          </div>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >Post Share Action Required</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="post_share_required"
                checked={this.state.post_share_required}
                id="post_share_required"
                onChange={(checked) => this.handleChange('post_share_required',checked)}
              />
            </div>
          </div>
        </div>
      </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >RLSD = 30 days in Future</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="rlsd_lt_30_future"
                  checked={this.state.rlsd_lt_30_future}
                  id="rlsd_lt_30_future"
                  onChange={(checked) => this.handleChange('rlsd_lt_30_future',checked)}
                />
              </div>
            </div>
            <div className="col-sm-2">
            </div>
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >Edit Alert</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="enabled_edit_alert"
                  checked={this.state.enabled_edit_alert}
                  id="enabled_edit_alert"
                  onChange={(checked) => this.handleChange('enabled_edit_alert',checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >RLSD in the Past</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="rlsd_past"
                  checked={this.state.rlsd_past}
                  id="rlsd_past"
                  onChange={(checked) => this.handleChange('rlsd_past',checked)}
                />
              </div>
            </div>
            <div className="col-sm-2">
            </div>
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >Post Edit Action Required</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="post_edit_action_required"
                  checked={this.state.post_edit_action_required}
                  id="post_edit_action_required"
                  onChange={(checked) => this.handleChange('post_edit_action_required',checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderEnableBanner()}
        {this.renderTextFields()}
        {this.bannerToggles()}
      </>
    )
  }
}

export default ConfigClaimBanner;
