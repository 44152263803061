import React from "react";
import { Container, useMediaQuery, Button } from "@material-ui/core";
import { useState } from "react";
import AuthLayout from "./AuthLayout";
import FormInput from "./FormInput";
import { useStyles } from "./useStyles";

function SignIn(props) {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    login: "",
    password: "",
  });

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordPresent, setIsPasswordPresent] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showMfaForm, setShowMfaForm] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otp, setOtp] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const validateInputs = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = true;
    if (!emailRegex.test(inputs.login.toLowerCase())) {
      isValid = false;
      setIsEmailValid(isValid);
    } else if (inputs.password.trim().length === 0) {
      isValid = false;
      setIsPasswordPresent(isValid);
    } else {
      setIsEmailValid(true);
      setIsPasswordPresent(true);
    }

    return isValid;
  };

  const handleSuccessfulLogin = (response, jqXHR) => {
    let redirectURL = Cookies.get("redirect-url");
    Cookies.set("is-super-admin", response.is_super_admin, {
      secure: true,
    });
    Cookies.set("admin-role", response.admin_role, { secure: true });
    Cookies.set("user-role", response.admin_role, { secure: true });
    Cookies.set("is-authenticated", "true", { secure: true });
    window.localStorage.setItem('company-id', response.default_company_id);
    window.location = redirectURL
      ? `/webapp${redirectURL}`
      : response.redirect_location;
    toastr.success("Signed in successfully.");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let method = "POST";
    let url = "/users/sign_in";

    if (validateInputs()) {
      setIsSubmitted(true);
      $.ajax({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ user: inputs }),
        dataType: "json",
        success: (response, _, jqXHR) => {
          if (response.mfa_required) {
            setShowMfaForm(true);
            setIsSubmitted(false);
          } else {
            handleSuccessfulLogin(response, jqXHR);
          }
        },
        error: (err) => {
          toastr.error(JSON.parse(err.responseText).message);
          setIsSubmitted(false);
        },
      });
    }
  };

  const handleMfaSubmit = (e) => {
    e.preventDefault();
    let method = "POST";
    let url = "/auth/mfa";
    setIsSubmitted(true);
    $.ajax({
      method: method,
      url: url,
      data: { otp },
      dataType: "json",
      success: (response, _, jqXHR) => {
        handleSuccessfulLogin(response, jqXHR);
      },
      error: (err) => {
        const response = JSON.parse(err.responseText);
        if (response.relogin) {
          toastr.error("Please login again.");
          setShowMfaForm(false);
        } else {
          setIsOtpValid(false);
        }
        setIsSubmitted(false);
      },
    });
  };

  const { login, password } = inputs;

  const renderForm = () => {
    if (showMfaForm) {
      return renderMfaForm();
    } else {
      return renderLoginForm();
    }
  };

  const renderSignInButton = () => {
    return (
      <div className={classes.formGroup}>
        <Button
          className="primary-login-button"
          type="submit"
          disabled={isSubmitted}
          style={{ fontFamily: "MontserratRegular" }}
        >
          Sign in
        </Button>
      </div>
    );
  };

  const renderMfaForm = () => {
    return (
      <form name="form" onSubmit={handleMfaSubmit}>
        <div className={classes.formDiv}>
          <FormInput
            type="text"
            name="otp"
            formLabel="Please enter your MFA code from your authenticator app (ex: Authy, Google Authenticator), or your backup code."
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            error={!isOtpValid}
            helperText={"Invalid two factor code."}
          />
        </div>
        {renderSignInButton()}
      </form>
    );
  };

  const renderLoginForm = () => {
    return (
      <form name="form" onSubmit={handleSubmit} className="sign-in-form">
        <div className={classes.formDiv}>
          <FormInput
            type="text"
            name="login"
            formLabel="Login"
            value={login}
            onChange={handleChange}
            error={!isEmailValid}
            helperText={"You must enter a valid email address"}
          />
          <FormInput
            type="password"
            name="password"
            formLabel="Password"
            value={password}
            onChange={handleChange}
            error={!isPasswordPresent}
            helperText={"Password is required"}
          />
          <div>
            <a className={classes.forgetPassword} href="/users/password/new">
              Forgot Password?
            </a>
          </div>
          {renderSignInButton()}
        </div>
      </form>
    );
  };

  return (
    <AuthLayout isConfidentialPlanning={props.is_confidential_planning}>
      <Container maxWidth="sm">
        <div className={classes.registrationDiv}>
          <div className={classes.headings}>
            <span className={classes.heading1}>
              <span className="underline"> Sign in</span>
            </span>
            {props.show_signup ? (
              <span className={classes.subHead}>
                Are you a new user?
                <a className={classes.signup} href="/users/sign_up">
                  Sign Up
                </a>
              </span>
            ) : (
              ""
            )}
          </div>
          {renderForm()}
        </div>
      </Container>
    </AuthLayout>
  );
}

export default SignIn;
